import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/SiteLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "what-is-this"
    }}>{`What is this?`}</h1>
    <p>{`This is a work in progress. Some day it will be a cool site about learning JavaScript using an interactive drawing setup. It will feature embedded code editors like the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "live=true id=landing-example",
        "live": "true",
        "id": "landing-example"
      }}>{`// Create some circles (invisible)
const circles = [0, 1, 2, 3, 4, 5].map(i => $circle(
  0,
  $stageWidth / 2,
  $stageHeight / 2,
  { fill: \`hsl(\${60 * i} 100% 50%)\`, shadowBlur: 2 }
));

// Animate them up
circles.forEach((circ, i) => {
  $animate({ item: circ, radius: $stageWidth/10 + 10, duration: 0.2, shadowBlur: 15 });
  $animate({ item: circ, x: i * $stageWidth/5, y: 0, duration: 0.3 });
});

// Drop them back down
$animate(...circles.map((circ, i) => ({
  item: circ,
  y: $stageHeight / 2,
  duration: (i + 1) * 0.2,
  shadowBlur: 2
})));
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      